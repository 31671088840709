import isEmpty from 'lodash.isempty';
import { addSlashToPath } from 'utils/tracking/track';

/**
 * Tracking template, replaces placeholders with values
 */
export function trackTemplate({ event, data }) {
  if (isEmpty(data)) {
    return null;
  }

  const { category, action, label, value, title, path } = data;

  if (event === 'genericPageview') {
    return {
      event,
      pageTitle: title,
      pagePath: addSlashToPath(path),
    };
  } else {
    return {
      event,
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    };
  }
}
