export const ICON_TYPES = {
  DEFAULT: 'ICON_TYPE_WEB20',
  WEB20: 'ICON_TYPE_WEB20',
};

export const ICON_COLORS = {
  BLACK: 'ICON_COLOR_BLACK',
  WHITE: 'ICON_COLOR_WHITE',
  PRIMARY: 'ICON_COLOR_PRIMARY',
  PRIMARY_DARK: 'ICON_COLOR_PRIMARY_DARK',
} as const;

const ID_TO_CLASS = {
  ICON_TYPE_WEB20: 'web20-icon web20-icon-',
  ICON_COLOR_WHITE: 'icon--white',
  ICON_COLOR_BLACK: 'icon--black',
  ICON_COLOR_PRIMARY: 'icon--primary',
  ICON_COLOR_PRIMARY_DARK: 'icon--primary-dark',
};

interface IconProps {
  symbol: string;
  type?: string;
  color?: typeof ICON_COLORS[keyof typeof ICON_COLORS];
  onClick?: () => void;
}

export function Icon({ color, type = ICON_TYPES.DEFAULT, symbol, onClick }: Readonly<IconProps>) {
  let classes = 'icon';
  if (color && typeof ID_TO_CLASS[color] !== 'undefined') {
    classes += ` ${ID_TO_CLASS[color]}`;
  }

  if (typeof ID_TO_CLASS[type] !== 'undefined') {
    classes += ` ${ID_TO_CLASS[type]}${symbol}`;
  }

  return <i className={classes} onClick={onClick} onKeyDown={onClick} />;
}
