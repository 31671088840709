import ReactHtmlParser from 'react-html-parser';

export function decodingContent<T = string>(
  content: string | number = '',
  textOnly?: boolean,
): T | string {
  let input = content;

  if (typeof input === 'number') {
    input = content.toString();
  }

  if (typeof input === 'string') {
    input = input.trim();
  }

  if (input) {
    const decodedContent = ReactHtmlParser(input, {
      decodeEntities: true,
    });

    if (textOnly) {
      return decodedContent.reduce((endString, currentItem) => {
        if (currentItem && typeof currentItem === 'object') {
          return endString.concat(currentItem?.props?.children ? currentItem.props.children.toString() : '');
        }

        return endString.concat(currentItem);
      }, '');
    }

    return decodedContent[0] as T;
  }

  return '';
}
