import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { usePathname } from 'next/navigation';
import { YouTubeEvent } from 'react-youtube';
import { Log } from 'services/log';
import { isPreviewSelector } from 'utils/selectors/environmentSelectors';
import { ID } from 'react-accessible-accordion/dist/types/components/ItemContext';

const selectFn = process.env.JEST_WORKER_ID !== undefined ? () => {} : useSelector;

/** Adds a slash to the provided url path if not present */
export function addSlashToPath(path: string) {
  if (!path.endsWith('/')) {
    path += '/';
  }
  return path;
}

/** GA4 tracking */
export function useTracking() {
  const isPreview = selectFn(isPreviewSelector);
  const pathname = usePathname();

  const asPathWithSlash = addSlashToPath(pathname);

  /** Pushes an object to the window.dataLayer array */
  const trackGA4Event = useCallback(
    (data: object) => {
      const ga4Data = { id: 'ga4Tracking', ...data };
      if (
        (window && ['local.web.geberit.com', 'localhost'].includes(window.location.hostname)) ||
        isPreview
      ) {
        Log.info('TRACKING EVENT', ga4Data);
      }

      const browserWindow = window as
        | undefined
        | (Window & {
            dataLayer?: object[];
          });

      if (browserWindow?.dataLayer) {
        browserWindow.dataLayer.push(ga4Data);
      }
    },
    [isPreview],
  );

  const trackScroll = useCallback(
    (percent_scrolled: number) => {
      trackGA4Event({
        event: 'scroll',
        url: asPathWithSlash,
        percent_scrolled,
      });
    },
    [trackGA4Event, asPathWithSlash],
  );

  const trackSearch = useCallback(
    (data: { content_type: 'e-paper portal (Oxomi)' | 'geberit site'; search_term: string }) => {
      trackGA4Event({ event: 'search', ...data });
    },
    [trackGA4Event],
  );

  const trackOxomi = useCallback(
    (data: {
      click_intent:
        | 'image click open - e-paper portal'
        | 'open pdf - e-paper portal'
        | 'download pdf';
      file_name: string;
      file_extension: 'pdf';
      value: number;
    }) => {
      trackGA4Event({ event: 'file_download', ...data });
    },
    [trackGA4Event],
  );

  const trackShareAction = useCallback(
    (data: { content_type: 'page' | 'video'; method: string; shareUrl?: string }) => {
      trackGA4Event({
        event: 'share',
        url: asPathWithSlash,
        value: data.content_type === 'page' ? 10 : 0,
        ...data,
      });
    },
    [trackGA4Event, asPathWithSlash],
  );

  const trackClick = useCallback(
    (data: {
      click_intent: string;
      click_element: string;
      click_text?: string;
      [key: string]: string | number | undefined;
    }) => {
      const clickEventData = { event: 'click', click_url: asPathWithSlash };
      trackGA4Event({ ...clickEventData, ...data });
    },
    [asPathWithSlash, trackGA4Event],
  );

  const trackLocator = useCallback(
    (data: {
      click_intent: string;
      search_term: string;
      value?: number;
      currency?: string;
      search_term_executed?: string;
    }) => {
      const clickEventData: Record<string, string> = {
        event: 'locator_interaction',
        content_type: 'locator',
      };
      if (data.value) {
        clickEventData.currency = data.currency ?? 'EUR';
      }
      trackGA4Event({ ...clickEventData, ...data });
    },
    [trackGA4Event],
  );

  const openedAccordionEntriesRef = useRef<Parameters<typeof trackAccordionOpening>[0]>([]);

  /** Call inside <Accordion onChange> from react-accessible-accordion
   * @example
   * onChange={(uuid) => trackAccordionOpening(uuid)}
   * */
  const trackAccordionOpening = useCallback(
    (uuid: ID[]) => {
      let shouldTrackOpening = false;

      // If more entries are opened than last time, it is an opening action
      if (uuid.length > openedAccordionEntriesRef.current.length) shouldTrackOpening = true;

      if (openedAccordionEntriesRef.current.length === 0) shouldTrackOpening = true;
      openedAccordionEntriesRef.current = uuid;

      if (!shouldTrackOpening) return;

      trackClick({
        click_element: 'accordion',
        click_intent: 'more_info',
        click_text: typeof uuid[0] === 'string' ? uuid[0] : undefined,
      });
    },
    [trackClick],
  );

  const trackMI24Video = useCallback(
    (ga4VideoTrackRef, playerApi, title, videoId) => {
      let videoStarted = false;

      playerApi.registerEventListener('play', () => {
        const currentTime = playerApi.getCurrentTime();
        if (currentTime === 0 && videoStarted === false) {
          trackGA4Event({
            event: 'video_start',
            video_title: title,
            video_id: videoId,
          });
          videoStarted = true;
        }
      });

      playerApi.registerEventListener('ended', () => {
        trackGA4Event({
          event: 'video_complete',
          video_title: title,
          video_id: videoId,
        });
        ga4VideoTrackRef.current = 0;
      });

      playerApi.registerEventListener('timeupdate', () => {
        const currentTime = playerApi.getCurrentTime();
        const totalDuration = playerApi.getDuration();
        const percent = Math.floor((currentTime * 100) / totalDuration);
        if (ga4VideoTrackRef.current < 25 && percent >= 25) {
          ga4VideoTrackRef.current = 25;
          trackGA4Event({
            event: 'video_progress',
            video_title: title,
            video_id: videoId,
            video_percent: ga4VideoTrackRef.current,
          });
        } else if (ga4VideoTrackRef.current < 50 && percent >= 50) {
          ga4VideoTrackRef.current = 50;
          trackGA4Event({
            event: 'video_progress',
            video_title: title,
            video_id: videoId,
            video_percent: ga4VideoTrackRef.current,
          });
        } else if (ga4VideoTrackRef.current < 75 && percent >= 75) {
          ga4VideoTrackRef.current = 75;
          trackGA4Event({
            event: 'video_progress',
            video_title: title,
            video_id: videoId,
            video_percent: ga4VideoTrackRef.current,
          });
        }
      });
    },
    [trackGA4Event],
  );

  const trackYoutubeVideo = useCallback(
    (
      event: YouTubeEvent<number>,
      videoId: string,
      title: string,
      isVideoStarted?: boolean,
      progress?: number,
    ) => {
      if (event.data === 1) {
        // only initial play should be tracked
        if (!isVideoStarted) {
          trackGA4Event({
            event: 'video_start',
            video_title: title,
            video_id: videoId,
          });
        } else if (progress) {
          trackGA4Event({
            event: 'video_progress',
            video_title: title,
            video_id: videoId,
            video_percent: progress,
          });
        }
      } else if (event.data === 0) {
        trackGA4Event({
          event: 'video_complete',
          video_title: title,
          video_id: videoId,
        });
      }
    },
    [trackGA4Event],
  );

  return {
    trackScroll,
    trackSearch,
    trackOxomi,
    trackShareAction,
    trackClick,
    trackLocator,
    trackAccordionOpening,
    trackMI24Video,
    trackYoutubeVideo,
  };
}

export type TrackOxomi = ReturnType<typeof useTracking>['trackOxomi'];
