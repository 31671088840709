import { THEME_NAMES } from 'themes';
import { useNord } from './use-nord';
import { useThemeName } from './use-theme';

export function useGdds(): boolean {
  const theme = useThemeName();
  const isNordics = useNord();

  return theme === THEME_NAMES.GDDS || isNordics;
}
